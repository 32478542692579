/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import _get from 'lodash/get';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/Container';
import SignUpModal from '../components/SignUpModal';
import Testimonials from '../components/Testimonials';
import EffortlessOnboarding from '../components/EffortlessOnboarding';
import NoPeskyEmails from '../components/NoPeskyEmails';
import VerticalTabSection from '../components/VerticalTabSection';
// Style
import {
  BodyTextBig,
  CTASection,
  DotsDivider,
  GlobalHero,
  GraySection,
  HeroHeading,
  HeroPreHeading,
  SectionTitle,
  SmallDarkText,
  SmallTitle,
  Table,
  WhiteSection,
} from '../components/Global';
import {
  TestimonialSection,
} from '../components/Solution/index.style';
import {
  TableWrapper,
  PriceBoxWrapper,
} from '../components/Comparison/index.style';


const updateNotificationData = [
  {
    title: 'Updates and notifications directly in Slack',
    subtitle: 'Notifications conveniently through Slack',
    description: 'Workast sends all task updates and reminders through Slack so you can keep your email inbox free of clutter. No need to keep switching between email and Slack to see the progress of a project - notifications where you need them.',
    image: 'notifications.png'
  },
  {
    title: 'Receive custom reports in Slack',
    subtitle: 'Receive custom reports in Slack',
    description: 'Create a custom report in Workast and select how often you want to receive the report. Workast will deliver the list of tasks to your Slack account and a copy to anyone else that needs it.',
    image: 'reports-in-slack.png'
  },
  {
    title: 'Daily reminders for all your work',
    subtitle: 'Daily reminders for all your work',
    description: 'Receive a daily task list of the things you need to do for the day in Slack so you can keep on top of all your work. Customize the reminders to suit your needs!',
    image: 'daily-remainders.png'
  },
];

const PriceBox = (props) => {
  const {
    name,
    price,
    message,
  } = props;

  const className = !price ? 'no-price' : '';

  return (
    <PriceBoxWrapper className={className}>
      <span className="title">{name}</span>
      {price && (
        <React.Fragment>
          <div className="main-price-container">
            <div className="main-price">
              {`$${price}`}
            </div>
            <div className="currency-container">
              <div className="currency">USD</div>
              <div className="period">/month</div>
            </div>
          </div>
          <div>{message}</div>
        </React.Fragment>
      )}
    </PriceBoxWrapper>
  );
};
PriceBox.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};


const ComparisonPage = ({ location, data }) => {
  const competitor = _get(data, 'contentfulComparisonPage.competitor', '');
  const heroTitle = _get(data, 'contentfulComparisonPage.heroTitle', '');
  const heroText = _get(data, 'contentfulComparisonPage.heroText.heroText', '');
  const heroImage = _get(data, 'contentfulComparisonPage.heroImage', {});
  const features = _get(data, 'contentfulComparisonPage.featuresComparison', []) || [];
  const essentialTitle = _get(data, 'contentfulComparisonPage.essentialTitle', '');
  let essentialPrice = _get(data, 'contentfulComparisonPage.essentialPrice', '');
  essentialPrice = (essentialPrice ?? '').split('|').map(item => item.trim());
  const standardTitle = _get(data, 'contentfulComparisonPage.standardTitle', '');
  let standardPrice = _get(data, 'contentfulComparisonPage.standardPrice', '');
  standardPrice = (standardPrice ?? '').split('|').map(item => item.trim());
  const professionalTitle = _get(data, 'contentfulComparisonPage.professionalTitle', '');
  let professionalPrice = _get(data, 'contentfulComparisonPage.professionalPrice', '');
  professionalPrice = (professionalPrice ?? '').split('|').map(item => item.trim());
  const reviews = _get(data, 'contentfulComparisonPage.reviews', []) || [];

  const featureItems = features.map((featureItem) => {
    const itemArray = featureItem.split('|');

    return {
      feature: itemArray[0],
      workast: itemArray[1],
      other: itemArray[2],
    };
  });
  const priceMessage = ' / additional user / mo.';
  const prices = [{
    workast: {
      name: 'Essential',
      price: '0',
      message: 'Free forever plan for unlimited users',
    },
    other: {
      name: essentialTitle || 'Basic',
      price: essentialPrice[0] ?? '',
      message: `${essentialPrice[1] ?? ''}`,
    },
  }, {
    workast: {
      name: 'Standard',
      price: '39',
      message: `$${4.95}${priceMessage}`,
    },
    other: {
      name: standardTitle || 'Standard',
      price: standardPrice[0] ?? '',
      message: `${standardPrice[1] ?? ''}`,
    },
  }, {
    workast: {
      name: 'Professional',
      price: '49',
      message: `$${9.95}${priceMessage}`,
    },
    other: {
      name: professionalTitle || 'Pro',
      price: professionalPrice[0] ?? '',
      message: `${professionalPrice[1] ?? ''}`,
    },
  }];
  const reviewItems = reviews.map(reviewItem => ({
    node: {
      ...reviewItem,
    },
  }));


  return (
    <Layout location={location}>
      <SEO
        title={`Compare Workast vs. ${competitor} | Workast - get the most of your work`}
        description={heroText}
        image={getImage(heroImage)}
      />
      <Container>
        <GlobalHero>
          <div className="hero-text">
            <HeroPreHeading>Comparisons</HeroPreHeading>
            <HeroHeading>{heroTitle}</HeroHeading>
            <BodyTextBig>{heroText}</BodyTextBig>
            <div className="button-wrap">
              <SignUpModal buttonText="Get started" />
            </div>
          </div>
          <div className="hero-img">
            <GatsbyImage image={getImage(heroImage)} alt={heroTitle} />
            <SignUpModal buttonText="Get started" />
          </div>
        </GlobalHero>
      </Container>


      {/* DIVIDER */}
      <DotsDivider />


      {/* DIFFERENCE */}
      <WhiteSection className="centered-text">
        <Container>
          <SectionTitle mb="15px" maxWidth="650px">
            {`What's the difference between Workast and ${competitor}?`}
          </SectionTitle>
          <BodyTextBig maxWidth="615px" mb="34px">
            {`See why ${competitor} users are transitioning to Workast to save time, money, and get the most out of their workflows.`}
          </BodyTextBig>
        </Container>
        <TableWrapper
          mt="50px"
        >
          <Table maxWidth="860px" columns={3}>
            <div className="row header">
              <div className="cell header" />
              <div className="cell header highlighted">Workast</div>
              <div className="cell header">{competitor}</div>
            </div>
            {featureItems.map(featureItem => (
              <div
                key={featureItem.feature}
                className="row"
              >
                <div className="cell bold">{featureItem.feature}</div>
                <div className="cell highlighted">{featureItem.workast}</div>
                <div className="cell">{featureItem.other}</div>
              </div>
            ))}
          </Table>
        </TableWrapper>

        <SignUpModal buttonText="Get started" />
      </WhiteSection>


      {/* PRICE DIFFERENCE */}
      <WhiteSection
        className="centered-text"
        padding="0"
      >
        <Container>
          <SectionTitle mb="15px">
            Affordable prices for small teams
          </SectionTitle>
          <BodyTextBig maxWidth="615px" mb="34px">
            We offer a completely free unlimited plan within Slack and Webex,
            so you can see if Workast works for you without having to commit to a trial.
          </BodyTextBig>
        </Container>

        <SmallTitle>For a team of 8 users</SmallTitle>
        <TableWrapper
          mt="30px"
        >
          <Table maxWidth="580px" columns={2}>
            <div className="row header">
              <div className="cell header highlighted">Workast</div>
              <div className="cell header">{competitor}</div>
            </div>
            {prices.map(priceItem => (
              <div
                key={priceItem.workast.name}
                className="row"
              >
                <div className="cell highlighted">
                  <PriceBox
                    name={priceItem.workast.name}
                    price={priceItem.workast.price}
                    message={priceItem.workast.message}
                  />
                </div>
                <div className="cell">
                  <PriceBox
                    name={priceItem.other.name}
                    price={priceItem.other.price}
                    message={priceItem.other.message}
                  />
                </div>
              </div>
            ))}
          </Table>
        </TableWrapper>

        <SignUpModal buttonText="Get started" />
      </WhiteSection>


      {/* A TOOL YOUR TEAM */}
      <WhiteSection className="centered-text">
        <Container>
          <SectionTitle mb="15px">
            A tool your team would actually use
          </SectionTitle>
          <SmallDarkText maxWidth="615px" mb="34px" darkFont>
            Workast integrates with the tools your team uses every day...
          </SmallDarkText>
        </Container>
      </WhiteSection>


      {/* EFFORTLESS ONBOARDING */}
      <EffortlessOnboarding />

      {/* NO PESKY EMAILS */}
      <NoPeskyEmails />

      {/* UPDATES AND NOTIFICATIONS */}
      <GraySection>
        <Container>
          <VerticalTabSection data={updateNotificationData} />
        </Container>
      </GraySection>


      {/* TESTIMONIALS */}
      {reviewItems.length > 0 && (
        <TestimonialSection>
          <SectionTitle mb="20px">
            Reviews
          </SectionTitle>
          <Testimonials data={reviewItems} />
        </TestimonialSection>
      )}

      {/* CTA */}
      <CTASection>
        <Container>
          <SectionTitle>
            {`Migrate from ${competitor} today`}
          </SectionTitle>
          <BodyTextBig>
            We can help import all your tasks and projects
          </BodyTextBig>
          <SignUpModal buttonText="Get started" />
        </Container>
      </CTASection>
    </Layout>
  );
};


/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
ComparisonPage.defaultProps = {
  data: {},
};
ComparisonPage.propTypes = {
  data: PropTypes.shape({
    contentfulComparisonPage: PropTypes.shape({
      competitor: PropTypes.string,
      essentialPrice: PropTypes.string,
      featuresComparison: PropTypes.arrayOf(
        PropTypes.string,
      ),
      heroImage: PropTypes.shape({
        gatsbyImageData: PropTypes.shape({}),
      }),
      heroText: PropTypes.shape({
        heroText: PropTypes.string,
      }),
      heroTitle: PropTypes.string,
      professionalPrice: PropTypes.string,
      reviews: PropTypes.arrayOf(
        PropTypes.shape({
        }),
      ),
      slug: PropTypes.string,
      standardPrice: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
};


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default ComparisonPage;
export const pageQuery = graphql`
  query ComparisonBySlug($slug: String!) {
    contentfulComparisonPage(slug: {eq: $slug}) {
      competitor
      slug
      heroTitle
      heroText {
        heroText
      }
      heroImage {
        gatsbyImageData(quality: 100)
      }
      featuresComparison
      essentialTitle
      essentialPrice
      standardTitle
      standardPrice
      professionalTitle
      professionalPrice
      reviews {
        id
        description {
          description
        }
        author
        role
        image {
          gatsbyImageData
        }
      }
    }
  }
`;
