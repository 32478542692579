/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
import PropTypes from 'prop-types';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import {
  Table,
} from '../Global';
import theme from '../../styles/theme';


const TableWrapper = styled.div`
  margin: ${props => props.mt} 16px 50px 16px;
  display: flex;
  justify-content: center;

  ${Table} {
    flex: 1;

    .row {
      &.header .cell {
        color: #595959;
        font-size: 28px;
        font-weight: 700;
        line-height: 34px;
        padding: 64px 0;
      }
      .cell {
        padding: 14px 0;

        &.bold {
          color: #000000;
          font-size: 18px;
          font-weight: 700;
          line-height: 26px;
          text-align: center;
        }
        &.highlighted {
          background: ${theme.body.gray};
        }
      }
    }
  }
`;
TableWrapper.displayName = 'TableWrapper';
TableWrapper.defaultProps = {
  mt: '0',
};
TableWrapper.propTypes = {
  mt: PropTypes.string,
};

const PriceBoxWrapper = styled.div`
  padding: 10px 10px 30px;

  &.no-price {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 10px 40px;
  }
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #262626;
  }
  .main-price-container {
    display: flex;
    justify-content: center;
    margin: 20px 0 16px 0;

    .main-price {
      font-weight: 900;
      font-size: 36px;
      line-height: 43px;
      color: #2C3346;
    }
    .currency-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 0 0 6px;
      text-align: initial;

      .currency {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #262626;
      }
      .period {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #424859;
      }
    }
  }
`;
PriceBoxWrapper.displayName = 'PriceBoxWrapper';


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export {
  TableWrapper,
  PriceBoxWrapper,
};
export default null;
