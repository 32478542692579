/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import MediaBreakDown from '../../styles/MediaBreakDown';


const TestimonialSection = styled.div`
  padding: 100px 0 0;
  text-align: center;
  ${MediaBreakDown.sm`
    padding: 60px 0 0;
  `}
`;


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export {
  TestimonialSection,
};
export default null;
