import React from 'react';
import styled from 'styled-components';
import Container from '../Container';
import Image from '../Image';
import {
  BodyTextBig,
  FlexWrap,
  SectionTitle,
} from '../Global';
import theme from '../../styles/theme';
import MediaBreakDown from '../../styles/MediaBreakDown';


const PeskySection = styled.div`
  padding: 145px 0;
  
  .image-wrap {
    width: 760px;
    .gatsby-image-wrapper {
      border: 1px solid ${theme.borderColor.grayBorder};
      border-radius: 10px;
    }
  }
  .text-wrap {
    width: 500px;
  }

  ${MediaBreakDown.exl`
    .image-wrap {
      width: 60%;
      .gatsby-image-wrapper {
        width: 98%;
        margin: auto;
      }
    }
    .text-wrap {
      width: 30%;
    }
  `}
  ${MediaBreakDown.lg`
    padding: 90px 0;
    .image-wrap {
      width: 100%;
      .gatsby-image-wrapper {
        max-width: 670px;
        margin: 30px auto 0;
      }
    }
    .text-wrap {
      width: 100%;
      text-align: center;
      max-width: 500px;
    }
  `}
  ${MediaBreakDown.lg`
    padding: 40px 0;
  `}
`;

const NoPeskyEmails = () => (
  <PeskySection>
    <Container>
      <FlexWrap direction="column-reverse" alignItems="center">
        <div className="image-wrap">
          <Image name="task-notifications.png" alt="no pesky emails" />
        </div>
        <div className="text-wrap">
          <SectionTitle mb="7px">
            No pesky emails
          </SectionTitle>
          <BodyTextBig>
            Notifications and reminders are sent through Workast
            in Slack so you don’t need to worry about flooding your inbox.
          </BodyTextBig>
        </div>
      </FlexWrap>
    </Container>
  </PeskySection>
);

NoPeskyEmails.propTypes = {
};

export default NoPeskyEmails;
