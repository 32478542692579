import React from 'react';
import styled from 'styled-components';
import Container from '../Container';
import Image from '../Image';
import {
  BodyTextBig,
  FlexWrap,
  SectionTitle,
} from '../Global';
import theme from '../../styles/theme';
import MediaBreakDown from '../../styles/MediaBreakDown';


const EffortlessSection = styled.div`
  margin: 0 32px;
  width: calc(100% - 64px);
  background: ${theme.body.gray};
  border-radius: 20px;

  .text-wrap {
    width: 760px;
  }
  .image-wrap {
    width: 456px;
  }
  ${MediaBreakDown.exl`
    .text-wrap {
      width: 60%;
    }
    .image-wrap {
      width: 35%;
    }
  `}
  ${MediaBreakDown.lg`
    margin: 0;
    width: 100%;
    border-radius: 0;
    padding: 35px 0;
  `}
  ${MediaBreakDown.sm`
    .text-wrap {
      width: 100%;
      margin: 20px auto 0;
      text-align: center;
    }
    .image-wrap {
      width: 200px;
      margin: auto;
    }
  `}
`;


const EffortlessOnboarding = () => (
  <EffortlessSection>
    <Container>
      <FlexWrap alignItems="center">
        <div className="text-wrap">
          <SectionTitle mb="11px">
            Effortless  Onboarding
          </SectionTitle>
          <BodyTextBig>
            Install Workast to your Slack account and in less than 1 minute,
            start collaborating on work with your team - no invites,
            separate passwords or new accounts.
          </BodyTextBig>
        </div>
        <div className="image-wrap">
          <Image name="effortless.png" alt="Effortless Onboarding" />
        </div>
      </FlexWrap>
    </Container>
  </EffortlessSection>
);

EffortlessOnboarding.propTypes = {
};

export default EffortlessOnboarding;
